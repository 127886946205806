<template>
    <div>
        <el-upload
            v-if="photoFile === null || this.uploadImage !== null"
            ref="upload"
            :show-file-list="false"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :file-list="fileList"
            :auto-upload="false"
            :limit="1"
            drag
            action=""
            :on-exceed="imageOverLimit"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG">
            <div class="relative w-full">
                <!-- 呈現字串得上傳框 -->
                <div v-if="!uploadImage & !imageURI" class="text-3xl font-light">
                    <i class="el-icon-circle-plus-outline mr-1"></i>
                    上傳存摺影本
                </div>
                <div v-else class="p-2">
                    <img class="w-full rounded-lg" :src="uploadImage ? uploadImage : imageURI" />
                </div>
            </div>
            <div slot="tip" class="el-upload__tip text-gray-300 text-center">只能上傳jpg/png文件，且不超過5mb</div>
        </el-upload>
    </div>
</template>

<script>
export default {
    name: "ProviderSalaryPhoto",
    props: {
        // 已上傳圖片
        imageURI: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            // 上傳圖片檔
            photoFile: null,
            // 上傳圖片 base64
            uploadImage: null,
            // 檔案列表
            fileList: [],
            loading: false,
        };
    },
    methods: {
        //圖片變更
        async handleChange(file) {
            // 清空上傳檔案列表 以免造成檔案上傳上限超過數量限制
            this.fileList = [];
            if (file.size > 5 * 1024 * 1024) {
                this.$message({
                    type: "error",
                    message: "圖片尺寸過大",
                });
                return;
            }
            // 設定圖片為上傳檔案
            this.photoFile = file.raw;
            this.uploadImage = await this.toBase64(this.photoFile);
            this.upLoadPhoto(this.photoFile);
        },
        // 將圖片轉為 base64
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        //移除圖片
        handleRemove() {
            this.photoFile = null;
        },
        //上傳圖片超過限制數量
        imageOverLimit(files, fileList) {
            console.log(fileList);
            this.$message({
                type: "error",
                message: "超過上傳圖片上限",
            });
        },
        /**
         * 上傳圖片
         * @param { type Blob or File(Blob 或 檔案格式) } file
         */
        async upLoadPhoto(file) {
            this.loading = true;
            try {
                this.loading = false;
                // 上傳成功時回傳成功給父組件
                this.$emit("onUploadBankPhotoSuccess", file);
            } catch (err) {
                this.loading = false;
                // 上傳失敗時回垂失敗給父組件
                this.$message({
                    type: "error",
                    message: "上傳圖片失敗",
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
    @apply min-h-[500px] flex w-full items-center justify-center;
}
::v-deep .el-upload-dragger {
    @apply h-auto w-full flex-grow overflow-visible;
}
::v-deep .el-upload {
    @apply w-full;
}
::v-deep .el-dialog {
    @screen sm {
        @apply w-[500px];
    }
    @apply w-auto;
}
</style>
